import * as React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../../layouts/Layout'
import heroImageMap from '../../components/MapSection/LargeMap.png'
import Button from '../../components/Button/Button'
import Hero from '../../components/Hero/Hero'
import Row from '../../components/Row/Row'
import { hero } from './login.module.scss'

const Pending = () => {
  return (
    <Layout getInTouchVisible={false} blueFooter={true}>
      <Helmet>
        <title>Login - Emso</title>
      </Helmet>
      <Hero header={'Login'} className={hero} image={heroImageMap} content={['Your account is currently pending activation']}>
        <Row>
          <Button to="/login/logout">Logout</Button>
        </Row>
      </Hero>
    </Layout>
  )
}

export default Pending;
